import React from "react";
import { Tr, Td, Text, useDisclosure } from "@chakra-ui/react";
import SettlementModal from "./SettlementModal";

const SettlementTableRow = ({ merchant, week, index , fetchMerchant}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Tr>
      <Td fontSize={{base:"10px",tablet:"16px"}}>{index + 1}</Td>
      <Td fontSize={{base:"10px",tablet:"16px"}}>{week?.duration}</Td>
      <Td fontSize={{base:"10px",tablet:"16px"}}>
        <Text
          cursor={"pointer"}
          color={"scarlet"}
          textDecor={"underline"}
          onClick={onOpen}
        >
          View Details
        </Text>
      </Td>
      <SettlementModal merchant={merchant} fetchMerchant={fetchMerchant} week={week} isOpen={isOpen} onClose={onClose} />
    </Tr>
  );
};

export default SettlementTableRow;
