import React from "react";
import { Select, VStack, Heading } from "@chakra-ui/react";
import OrderTable from "./OrderTable";

const PastOrders = ({ orders, dateRange, setDateRange}) => {
  return (
    <VStack w="full" alignItems={"start"} gap={8}>
      <Heading fontWeight={{base:"400",tablet:"600"}} fontSize={{base:"20px",md:"22px",tablet:"24px"}} fontFamily={"Poppins"}>
        Past Orders
      </Heading>
      <VStack alignItems={"start"} gap={0} w="full">
        <Select
          boxShadow={"0px 2px 6px rgba(0,0,0,0.25)"}
          border={"none"}
          w="auto"
          placeholder="Date Range"
          value={dateRange}
          onChange={(e) => setDateRange(e.target.value)}
          fontWeight={"medium"}
          fontFamily={"Barlow"}
        >
          <option value="today">Today</option>
          <option value="week">Week</option>
          <option value="month">Month</option>
        </Select>
        <OrderTable orders={orders} />
      </VStack>
    </VStack>
  );
};

export default PastOrders;
