import { useState, useEffect, useContext } from "react";
import { VStack, Select, HStack } from "@chakra-ui/react";
import EarningTable from '../components/Earnings/EarningTable';
import { axiosInstance } from "../Axios";
import { CityContext } from "../context/CityContext";
import { useOutletContext } from "react-router-dom";

const Earnings = () => {
  const [city, setCity] = useState("");
  const [dateRange, setDateRange] = useState(""); // Default date range
  const [orders, setOrders] = useState([]);
  const { cities } = useContext(CityContext);
  const { searchInput } = useOutletContext();

  const fetchOrders = async () => {
    try {
      const res = await axiosInstance.get(`/admin/orders?city=${city}&dateRange=${dateRange}`);
      setOrders(res?.data?.list);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchOrders();
  }, [city, dateRange]);

  const filteredorders = orders.filter((order) =>
    order.id.toLowerCase().includes(searchInput.toLowerCase())
  );

  return (
    <VStack
      bg={"#FFF"}
      h="full"
      m={{base:0,tablet:10}}
      borderRadius={"20px"}
      alignItems={"start"}
      p={10}
      w={{ base: "100%", tablet: "1038px" }} // Responsive width
      overflowX="hidden" // Prevent horizontal overflow
    >
      <HStack>
        <Select
          boxShadow={"0px 2px 6px rgba(0,0,0,0.25)"}
          border={"none"}
          w="auto"
          placeholder="Date Range"
          fontWeight={"medium"}
          fontFamily={"Barlow"}
          onChange={(e) => setDateRange(e.target.value)}
        >
          <option value={"today"}>Today</option>
          <option value={"week"}>Week</option>
          <option value={"month"}>Month</option>
        </Select>
        <Select
          boxShadow={"0px 2px 6px rgba(0,0,0,0.25)"}
          border={"none"}
          w="auto"
          placeholder="City"
          fontWeight={"medium"}
          fontFamily={"Barlow"}
          onChange={(e) => setCity(e.target.value)}
        >
          {cities.length > 0 ? (
            cities.map((city) => (
              <option key={city.cityId} value={city.cityName}>
                {city.cityName}
              </option>
            ))
          ) : (
            <option disabled>Loading cities...</option>
          )}
        </Select>
      </HStack>
      <EarningTable orders={filteredorders} />
    </VStack>
  );
}

export default Earnings;
