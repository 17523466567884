import React, { useEffect, useState, useContext } from "react";
import { VStack, HStack, Select, Flex } from "@chakra-ui/react";
import Card from "../components/Home/Card";
import { axiosInstance } from "../Axios";
import { CityContext } from "../context/CityContext";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const [city, setCity] = useState("");
  const [dateRange, setDateRange] = useState(""); 
  const [data, setData] = useState({
    totalCustomers: 0,
    totalMerchants: 0,
    placedOrdersCount: 0,
    netRevenue: 0,
    profit: 0,
    netSettlement: 0,
  });
  const { cities } = useContext(CityContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await axiosInstance.get("admin/home", {
          params: { city, dateRange },
        });
        setData(response.data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchCustomers();
  }, [city, dateRange]);

  return (
    <VStack 
      p={10} 
      w={["100%", "100%", "80%"]}  // Full width for mobile, 80% for larger screens
      alignItems="start"
      gap={8}
    >
      <HStack
        w="full"
        spacing={4}
        flexWrap={["wrap", "nowrap"]}  // Wrap filters in mobile, no-wrap in larger screens
      >
        {/* Date Range Select */}
        <Select
          boxShadow="0px 2px 6px rgba(0,0,0,0.25)"
          border="none"
          w={["full", "auto"]}  // Full width on mobile, auto width on larger screens
          value={dateRange}
          placeholder="Date Range"
          fontWeight="medium"
          fontFamily="Barlow"
          bg="#FFF"
          onChange={(e) => setDateRange(e.target.value)}
        >
          <option value="today">Today</option>
          <option value="week">Week</option>
          <option value="month">Month</option>
        </Select>

        {/* City Select */}
        <Select
          boxShadow="0px 2px 6px rgba(0,0,0,0.25)"
          border="none"
          w={["full", "auto"]}  // Full width on mobile, auto width on larger screens
          placeholder="City"
          fontWeight="medium"
          fontFamily="Barlow"
          onChange={(e) => setCity(e.target.value)}
          bg="#FFF"
        >
          {cities.length > 0 ? (
            cities.map((city) => (
              <option key={city.cityId} value={city.cityName}>
                {city.cityName}
              </option>
            ))
          ) : (
            <option disabled>Loading cities...</option>
          )}
        </Select>
      </HStack>

      <Flex
        w="full"
        flexWrap="wrap"
        gap={6}  
        justifyContent={{ base: "center", md: "space-between", lg: "flex-start" }} 
      >
        <Card
          img={"./images/card_total_order.svg"}
          figure={data.totalCustomers}
          desc={"Customers Onboard"}
        />
        <Card
          img={"./images/card_total_order (1).svg"}
          figure={data.totalMerchants}
          desc={"Merchants Onboard"}
        />
        <Card
          img={"./images/home-shopping-bag.svg"}
          figure={data.placedOrdersCount}
          desc={"Orders Placed"}
        />
        <Card
          img={"./images/home-bill.svg"}
          figure={Math.floor(data.netRevenue)}
          desc={"Net Revenue"}
        />
        <Card
          img={"./images/home-bill.svg"}
          figure={Math.floor(data.profit)}
          desc={"Net Profit"}
        />
        <Card
          img={"./images/home-price-tag.svg"}
          figure={data.netSettlement}
          desc={"Net Settlement"}
        />
      </Flex>
    </VStack>
  );
};

export default Home;
