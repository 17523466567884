import { Select, VStack } from "@chakra-ui/react";
import React, { useEffect, useState, useContext } from "react";
import AccountTable from "../components/AccountApproval/AccountTable";
import { axiosInstance } from "../Axios";
import { CityContext } from "../context/CityContext";
import { useOutletContext } from "react-router-dom";

const AccountApproval = () => {
  const [nonAccountApproval, setNonAccountApproval] = useState([]);
  const [city, setCity] = useState("");
  const { cities } = useContext(CityContext);
  const { searchInput } = useOutletContext();

  useEffect(() => {
    const fetchNonAccountApprovalUsers = async () => {
      try {
        const response = await axiosInstance.get("admin/approval", {
          params: { city },
        });
        setNonAccountApproval(response.data);
      } catch (err) {
        console.error(err.message);
      }
    };

    fetchNonAccountApprovalUsers();
  }, [city]);

  const filteredNonAccountApproval = nonAccountApproval.filter((user) =>
    user.name.toLowerCase().includes(searchInput.toLowerCase())
  );
  
  return (
    <VStack
      bg={"#FFF"}
      h="full"
      m={{base:0,tablet:10}}
      borderRadius={"20px"}
      alignItems={"start"}
      p={10}
      w={{ base: "100%", tablet: "1038px" }} // Responsive width
      overflowX="hidden" // Prevent horizontal overflow
    >
      <Select
        boxShadow={"0px 2px 6px rgba(0,0,0,0.25)"}
        border={"none"}
        w={{ base: "auto", tablet: "auto" }} // Full width on small screens
        placeholder="City"
        fontWeight={"medium"}
        fontFamily={"Barlow"}
        onChange={(e) => setCity(e.target.value)}
      >
        {cities.length > 0 ? (
                        cities.map((city) => (
                        <option key={city.cityId} value={city.cityName}>
                            {city.cityName}
                        </option>
                    ))
                    ) : (
                        <option disabled>Loading cities...</option>
                    )}
      </Select>
      <AccountTable
        users={filteredNonAccountApproval}
        setUsers={setNonAccountApproval}
      />
    </VStack>
  );
};

export default AccountApproval;
