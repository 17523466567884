import React, {useContext} from "react";
import { VStack, Image, Button, HStack } from "@chakra-ui/react";
import SidebarList from "./SidebarList";
import { GoHome } from "react-icons/go";
import { LuUser2 } from "react-icons/lu";
import { HiOutlineUsers } from "react-icons/hi2";
import { IoMdList } from "react-icons/io";
import { FaRegChartBar } from "react-icons/fa";
import { BsGraphUpArrow } from "react-icons/bs";
import { PiUsersThree } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

const Sidebar = ({onClose}) => {
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext);
  
  // Logout function
  const handleLogout = () => {
    logout();
    navigate("/login");
  };
  
  return (
    <VStack
      zIndex={1}
      w="326px"
      h="900px"
      bg={"#FFF"}
      alignItems={"left"}
      pt={8}
      px={"28px"}
    >
      <Image src="/images/mainLogo.png" w="111px" h="35px" />
      <VStack alignItems={"left"} mt={16}>
        <SidebarList text="Home" icon={GoHome} onClose = {onClose} path="/" />
        <SidebarList text="Account Approval" icon={LuUser2} onClose = {onClose} path="/account-approval" />
        <SidebarList text="All Customers" icon={HiOutlineUsers} onClose = {onClose} path="/customers" />
        <SidebarList text="All Merchants" icon={PiUsersThree} onClose = {onClose} path="/merchants" />
        <SidebarList text="All Orders" icon={IoMdList} onClose = {onClose} path="/orders" />
        <SidebarList text="Earnings" icon={BsGraphUpArrow} onClose = {onClose} path="/earnings" />
        <SidebarList text="Settlements" icon={FaRegChartBar} onClose = {onClose} path="/settlements" />
      </VStack>
      <Image src="/images/Frame.png" w="250px" h="150px" display={{base:"none",md:"block",tablet:"block"}} mt={{base:"50px",tablet:"150px"}} ></Image>
      <HStack mt="53px">
      <Button
        colorScheme="#FFFFFF"
        onClick={handleLogout} // Trigger logout on click
        w="123px"
        h="48px"
        mt="auto"
        gap="10px"
        padding={"12px 17px 12px 17px"}
        boxShadow=" 0px 2px 10px 0px rgba(0, 0, 0, 0.25)"
        borderRadius="20px"
        color="#9197B3"
      >
      <Image src = "/images/vector.png" w="20px" h = "18px"></Image>
        Log out
      </Button>
      <Image src= "/images/icon.png" w="49px" h="49px" ml="90px"></Image>
      </HStack>
    </VStack>
  );
};

export default Sidebar;
