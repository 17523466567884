import { useState, useEffect } from "react";
import {
  HStack,
  VStack,
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Heading,
} from "@chakra-ui/react";
import Product from "./Product";
import { axiosInstance } from "../../Axios";

const OrderModal = ({ orderId, isOpen, onClose }) => {
  const [order, setOrder] = useState({});

  // Fetch order details based on the orderId
  const fetchOrder = async () => {
    try {
      const response = await axiosInstance.get(`admin/order/${orderId}`);
      setOrder(response.data);
    } catch (error) {
      console.log("Error fetching order:", error.message);
    }
  };

  useEffect(() => {
    if (isOpen) fetchOrder();
  }, [isOpen]);

  // const textStyle = {
  //   fontSize: { base: "16px", md:"",tablet: "18px" },
  //   fontFamily: "Poppins",
  //   fontWeight: "600",
  // };
  const hStackStyle = {
    width: "100%",
    justifyContent: "space-between",
  };

  return (
    <Modal size={{ base: "",md:"",tablet: "" }} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent width={{ base: "", md:"",tablet: "1000px" }} height={{base:"",md:"",tablet:"889px"}}borderRadius={"20px"}>
        <ModalCloseButton />
        <ModalBody p={{ base: 4, md:7,tablet: 10 }} w="full">
          <VStack gap={{ base: 5, md:4.5,tablet: 16 }} w="full" alignItems={"start"}>
            <HStack
              flexDirection={{ base: "column", md:"row",tablet: "row" }}
              alignItems={"start"}
              w="full"
              gap={10}
            >
              <VStack
                alignItems={"start"}
                w={{ base: "100%", md:"60%"}}
                borderRight={{ base: "none", md:"",tablet: "1px solid #E9E9E9" }}
                pr={{ base: 0, md:0,tablet: 10 }}
              >
                <HStack style={hStackStyle} fontSize={{ base: "12px", md:"12px",tablet: "18px" }}>
                  <Text fontSize={{base:"12px",md:"12px",tablet:"18px"}} fontFamily={"Poppins"} fontWeight={600}>Order ID:</Text>
                  <Text>{order?.id}</Text>
                </HStack>
                <HStack style={hStackStyle} fontSize={{ base: "12px", md:"12px",tablet: "18px" }}>
                  <Text fontSize={{base:"12px",md:"12px",tablet:"18px"}} fontFamily={"Poppins"} fontWeight={600}>Date & Time:</Text>
                  <Text>{order?.createdAt}</Text>
                </HStack>
                <HStack style={hStackStyle} fontSize={{ base: "12px", md:"12px",tablet: "18px" }}>
                  <Text fontSize={{base:"12px",md:"12px",tablet:"18px"}} fontFamily={"Poppins"} fontWeight={600}>Customer Name:</Text>
                  <Text>{order?.orderBy}</Text>
                </HStack>
                <HStack style={hStackStyle} fontSize={{ base: "12px", md:"12px",tablet: "18px" }}>
                  <Text fontSize={{base:"12px",md:"12px",tablet:"18px"}} fontFamily={"Poppins"} fontWeight={600}>Phone Number:</Text>
                  <Text>{order?.phoneNumber}</Text>
                </HStack>
                <HStack style={hStackStyle} fontSize={{ base: "12px", md:"12px",tablet: "18px" }}>
                  <Text fontSize={{base:"12px",md:"12px",tablet:"18px"}} fontFamily={"Poppins"} fontWeight={600}>Address:</Text>
                  <Text>{order?.address}</Text>
                </HStack>
              </VStack>
              <VStack alignItems={"start"} w={{ base: "100%", md: "30%" }}>
                <HStack style={hStackStyle} fontSize={{ base: "12px", md:"12px",tablet: "18px" }}>
                  <Text fontSize={{base:"12px",md:"12px",tablet:"18px"}} fontFamily={"Poppins"} fontWeight={600}>Message:</Text>
                  <Text>{order?.note}</Text>
                </HStack>
                <HStack style={hStackStyle} fontSize={{ base: "12px", md:"12px",tablet: "18px" }}>
                  <Text fontSize={{base:"12px",md:"12px",tablet:"18px"}} fontFamily={"Poppins"} fontWeight={600}>Baker Name:</Text>
                  <Text>{order?.acceptedBy?.name}</Text>
                </HStack>
                <HStack style={hStackStyle} fontSize={{ base: "12px", md:"12px",tablet: "18px" }}>
                  <Text fontSize={{base:"12px",md:"12px",tablet:"18px"}} fontFamily={"Poppins"} fontWeight={600}>Baker Contact:</Text>
                  <Text>{order?.acceptedBy?.phoneNumber}</Text>
                </HStack>
                <HStack style={hStackStyle} fontSize={{ base: "12px", md:"12px",tablet: "18px" }}>
                  <Text fontSize={{base:"12px",md:"12px",tablet:"18px"}} fontFamily={"Poppins"} fontWeight={600}>Status:</Text>
                  <Text>{order?.status}</Text>
                </HStack>
              </VStack>
            </HStack>

            <VStack alignItems={"start"} gap={{base:5,md:4.5,tablet:6}} w="full">
              <Heading fontSize={{ base: "12px", md:"12px",tablet: "18px" }} fontWeight={"600"}>
                Product Details
              </Heading>
              <HStack gap={4} w="full" flexDirection={{base:"column",md:"row",tablet:"row"}} overflowX={"scroll"} overflowY={{base:"scroll"}}>
                {order?.items?.map((product) => {
                  return <Product key={product?.id} product={product} />;
                })}
              </HStack>
            </VStack>

            <VStack alignItems={"start"} gap={{base:5,md:4.5,tablet:6}} w="full">
              <Heading fontSize={{ base: "12px", md:"12px",tablet: "18px" }} fontWeight={"600"}>
                Price Summary
              </Heading>
              <HStack
                flexDirection={{ base: "column", md:"row",tablet: "row" }}
                gap={10}
                w="full"
                alignItems={"start"}
              >
                <VStack
                  alignItems={"start"}
                  w={{ base: "100%", md:"30%"}}
                  borderRight={{ base: "none",tablet: "1px solid #E9E9E9" }}
                  pr={{ base: 0,tablet: 10 }}
                >
                  <HStack style={hStackStyle} fontSize={{ base: "12px", md:"12px",tablet: "18px" }}>
                    <Text>Item Subtotal:</Text>
                    <Text>₹ {order?.itemSubtotal}</Text>
                  </HStack>
                  <HStack style={hStackStyle} fontSize={{ base: "12px", md:"12px",tablet: "18px" }}>
                    <Text>Discount:</Text>
                    <Text>₹ {order?.discountedAmount}</Text>
                  </HStack>
                  <HStack style={hStackStyle} fontSize={{ base: "12px", md:"12px",tablet: "18px" }}>
                    <Text>Delivery Charge:</Text>
                    <Text>₹ {order?.deliveryCharge}</Text>
                  </HStack>
                  <HStack style={hStackStyle} fontSize={{ base: "12px", md:"12px",tablet: "18px" }}>
                    <Text>Platform Fee:</Text>
                    <Text>₹ {order?.platformCharge}</Text>
                  </HStack>
                  <HStack style={hStackStyle} fontSize={{ base: "12px", md:"12px",tablet: "18px" }}>
                    <Text>Grand Total:</Text>
                    <Text>₹ {order?.grandTotal}</Text>
                  </HStack>
                </VStack>
                <VStack alignItems={"start"} w={{ base: "100%",md: "30%" }}>
                  <HStack style={hStackStyle} fontSize={{ base: "12px", md:"12px",tablet: "18px" }}>
                    <Text>Merchant Subtotal:</Text>
                    <Text>₹ {order?.itemSubtotal}</Text>
                  </HStack>
                  <HStack style={hStackStyle} fontSize={{ base: "12px", md:"12px",tablet: "18px" }}>
                    <Text>Merchant Delivery:</Text>
                    <Text>₹{order?.merchantDeliveryFee}</Text>
                  </HStack>
                  <HStack style={hStackStyle} fontSize={{ base: "12px", md:"12px",tablet: "18px" }}>
                    <Text>Merchant Total:</Text>
                    <Text>₹{(order?.itemSubtotal+order?.merchantDeliveryFee)}</Text>
                  </HStack>
                  <HStack style={hStackStyle} fontSize={{ base: "12px", md:"12px",tablet: "18px" }}>
                    <Text>PG Fees:</Text>
                    <Text>₹{order?.pgFees}</Text>
                  </HStack>
                  <HStack style={hStackStyle} fontSize={{ base: "12px", md:"12px",tablet: "18px" }}>
                    <Text>Margin:</Text>
                    <Text>₹{order?.commission}</Text>
                  </HStack>
                </VStack>
              </HStack>
            </VStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default OrderModal;
