import React from "react";
import {
  Card,
  CardBody,
  Image,
  Text,
  Heading,
  Flex,
  VStack,
} from "@chakra-ui/react";
const Item = ({ figure, desc, img }) => {
  return (
    <Card
      boxShadow={"0px 2px 8px rgba(0, 0, 0, 0.25)"}
      borderRadius={"14px"}
      bg={"#FFF"}
      w={"265px"}
      h={"170px"}
    >
      <CardBody display={"flex"} flexDir={"row"} alignItems={"center"} gap={8}>
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          bg={"rgba(255, 22, 22, 0.15)"}
          w={{base:"65px",tablet:"85px"}}
          h={{base:"65px",tablet:"85px"}}
          borderRadius={"50%"}
        >
          <Image src={img} w={{base:"30px",tablet:"50px"}} h={{base:"30px",tablet:"50px"}} />
        </Flex>
        <VStack alignItems={"start"}>
          <Heading
            color={"gunPowder"}
            fontSize={{base:"20px",tablet:"40px"}}
            fontWeight={"700"}
            fontFamily={"Barlow"}
          >
            {figure}
          </Heading>
          <Text
            color={"gunPowder"}
            fontFamily={"Barlow"}
            fontWeight={"400"}
            fontSize={{base:"12px",tablet:"16px"}}
            w={{base:"60px",tablet:"80px"}}
          >
            {desc}
          </Text>
        </VStack>
      </CardBody>
    </Card>
  );
};

export default Item;
