import React from "react";
import {
  TableContainer,
  Table,
  Tr,
  Th,
  Thead,
  Tbody,
} from "@chakra-ui/react";
import OrderTableRow from "./OrderTableRow";

const OrderTable = ({ orders }) => {
  return (
    <TableContainer
      boxShadow={"0px 2px 10px rgba(0,0,0,0.25)"}
      borderRadius={"12px"}
      mt={6}
      w="full"
      maxH="358px"
      overflowY={"scroll"}
      overflowX={{base:"scroll"}}
    >
      <Table variant="simple" size={{ base: "sm",md:"md"}}>
        <Thead bg={"wildSand"} position={"sticky"} top={0} zIndex={1}>
          <Tr>
            <Th fontSize={{base:"10px",md:"12px",tablet:"14px"}}>S.No.</Th>
            <Th fontSize={{base:"10px",md:"12px",tablet:"14px"}}>Order ID</Th>
            <Th fontSize={{base:"10px",md:"12px",tablet:"14px"}}>Details</Th>
          </Tr>
        </Thead>
        <Tbody>
          {orders?.map((order, index) => {
            return <OrderTableRow order={order} index={index} key={index} />
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default OrderTable;
