import { Select, VStack } from '@chakra-ui/react';
import React, { useEffect, useState, useContext } from 'react';
import AllCustomersTable from '../components/AllCustomers/AllCustomersTable';
import { axiosInstance } from '../Axios';
import { CityContext } from '../context/CityContext';
import { useOutletContext } from "react-router-dom";

const Customers = () => {
    const [city, setCity] = useState("");
    const [allCustomer, setAllCustomers] = useState([])
    const { cities } = useContext(CityContext);
    const { searchInput } = useOutletContext();

    useEffect(() => {
        const fetchAllCustomers = async () => {
            try {
                const response = await axiosInstance.get("admin/getAllCustomers", {
                    params: { city },
                });
                setAllCustomers(response.data);
            } catch (err) {
                console.error(err.message);
            }
        };

        fetchAllCustomers();
    }, [city]);

    const filteredcustomer = allCustomer.filter((user) =>
        user.name.toLowerCase().includes(searchInput.toLowerCase())
      );

    return (
        <VStack
            bg={"#FFF"}
            h="full"
            m={{base:0,tablet:10}}
            borderRadius={"20px"}
            alignItems={"start"}
            p={10}
            w={{ base: "100%", tablet: "1038px" }} // Responsive width
            overflowX="hidden" // Prevent horizontal overflow
        >
            <Select
                boxShadow={"0px 2px 6px rgba(0,0,0,0.25)"}
                border={"none"}
                w={{ base: "auto", tablet: "auto" }}
                placeholder="City"
                fontWeight={"medium"}
                fontFamily={"Barlow"}
                onChange={(e) => setCity(e.target.value)}
            >
                {cities.length > 0 ? (
                    cities.map((city) => (
                        <option key={city.cityId} value={city.cityName}>
                            {city.cityName}
                        </option>
                    ))
                ) : (
                    <option disabled>Loading cities...</option>
                )}
            </Select>
            <AllCustomersTable
                users={filteredcustomer}
            />
        </VStack>
    );
}

export default Customers;
