import React from "react";
import { Table, Thead, Tbody, Tr, Th, TableContainer } from "@chakra-ui/react";
import UserTableRow from "./UserTableRow";

const UserTable = ({ users }) => {
  const thStyle = {
    fontFamily: "Roboto",
    fontWeight: "600",
    color: "black",
    textTransform: "none",
    fontSize: "16px",
  };

  return (
    <TableContainer
      boxShadow={"0px 2px 10px rgba(0,0,0,0.25)"}
      borderRadius={"12px"}
      mt={6}
      w="full"
      h="358px"
      overflowY={"scroll"}
      overflowX={{base:"scroll"}}
    >
      <Table variant="simple" size={{ base: "sm", md: "md" }}>
        <Thead bg={"wildSand"} position={"sticky"} top={0} zIndex={1}>
          <Tr>
            <Th style={thStyle} fontSize={{base:"10px",tablet:"16px"}}>S.No</Th>
            <Th style={thStyle} fontSize={{base:"10px",tablet:"16px"}}>Name</Th>
            <Th style={thStyle} fontSize={{base:"10px",tablet:"16px"}}>Phone no.</Th>
            <Th style={thStyle} fontSize={{base:"10px",tablet:"16px"}}>Details</Th>
          </Tr>
        </Thead>
        <Tbody>
          {users?.map((user, i) => {
            return <UserTableRow key={i} user={user} index={i} />;
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default UserTable;
